import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

const BaseSearchContainer = css`
  background: ${(props) => props.theme.palette.paperAlpha};
  padding: 10px 0;
`

export const InputContainer = styled.div`
  flex: 1 1 auto;
  margin: 0 10px;
  
  input {
    padding: 5px;
    border: 0;
    background: transparent;
    border-radius: 0;
    outline: 0;
    width: 100%;
  }
`;

export const MobileSearchFieldContainer = styled.div`
  ${BaseSearchContainer};
  width: 100%;
`;

export const DesktopSearchFieldContainer = styled(animated.div)`
  ${BaseSearchContainer};
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.22);
  width: 100%;
`

export const SearchTrigger = styled.span`
  display: flex;
  align-items: center;
  
  cursor: pointer;
  padding: 0 20px;
  background: ${(props) => props.$active ? props.theme.palette.paperAlpha : 'transparent'};
  
  &:hover {
    background: ${(props) => props.theme.palette.paperAlpha};
  }
`;

export const SearchForm = styled.form`
  display: flex;
  align-items: center;
`;

