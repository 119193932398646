import React, { useState, useRef, useEffect } from 'react';
import usePortal from 'react-useportal';
import { useSpring } from 'react-spring';
import { Container, Row, Col } from 'react-grid-system';


import { useMobileNav } from '../Navigation/utils';
import Icon from '../Icon';
import Button from '../Button';
import * as S from './styles';

const SearchFieldContainer = React.forwardRef(({
  active,
  onSuccess,
}, ref) => {
  const isMobileNav = useMobileNav();
  const [query, setQuery] = useState('');
  const { Portal } = usePortal();
  const searchAnimation = useSpring({
    to: {
      top: active ? '94px' : '0px',
      transform: `translateY(${active ? '0' : '-100%'})`,
    },
  });

  const Component = (
    <Container>
      <Row>
        <Col>
          <S.SearchForm onSubmit={(ev) => {
            ev.preventDefault();

            onSuccess(query)
          }}>
            <Icon name='search' />
            <S.InputContainer>
              <input
                ref={ref}
                type='search'
                placeholder='Search for a program, conference or news article'
                onChange={(e) => setQuery(e.target.value)}
                value={query}
              />
            </S.InputContainer>
            <Button
              as='button'
              type='submit'
              iconName='arrow-right'
              label='Search'
              variant='clean'
            />
          </S.SearchForm>
        </Col>
      </Row>
    </Container>
  );

  if (isMobileNav) {
    return (
      <S.MobileSearchFieldContainer>
        {Component}
      </S.MobileSearchFieldContainer>
    )
  }

  return (
    <Portal>
      <S.DesktopSearchFieldContainer style={searchAnimation}>
        {Component}
      </S.DesktopSearchFieldContainer>
    </Portal>
  );
})

const Search = ({
  onSuccess,
}) => {
  const isMobileNav = useMobileNav();
  const [active, setActive] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (active) {
      inputRef.current.focus();
    }
  }, [active]);

  if (isMobileNav) {
    return (
      <SearchFieldContainer
        onSuccess={(q) => {
          setActive(false);
          onSuccess(q);
        }}
        ref={inputRef}
        active={active}
      />
    )
  }

  return (
    <>
      <S.SearchTrigger $active={active} onClick={() => setActive(!active)}>
        <Icon name='search' size={18} color='black' />
      </S.SearchTrigger>
      <SearchFieldContainer
        onSuccess={(q) => {
          setActive(false);
          onSuccess(q);
        }}
        ref={inputRef}
        active={active}
      />
    </>
  )
};

export default Search;
