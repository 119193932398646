import { useScreenClass } from 'react-grid-system';
import { useEffect, useMemo } from 'react';
import { globalHistory } from '@reach/router';

const MobileBreakpoints = ['xs', 'sm', 'md'];

export const useMobileNav = () => {
  const screenClass = useScreenClass();

  return useMemo(() => {
    return MobileBreakpoints.indexOf(screenClass) !== -1;
  }, [screenClass]);
};


export const useRouteChange = (cb) => useEffect(() => {
  const listener = globalHistory.listen(cb);

  return () => {
    listener();
  }
}, [cb]);
